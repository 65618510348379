import analyticsManager from '@lmig/crab-rangoons';
import env from './env';

const tealiumPageLoad = (pageSubName: string) => {

  const payload = {
    pageName: env.pageName,
    pageSubName
  };

  analyticsManager.dispatchPageload(payload);
};

const tealiumEvent = (eventName: string, event: any) => {
  
  const payload = {
    pageName: env.pageName,
    eventName,
    ...event
  };

  analyticsManager.dispatch(payload);
};

export { tealiumPageLoad, tealiumEvent };
