import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { tealiumPageLoad } from './utils/tealium';
import routesConfig from './routes-config';

const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    const currentRoute = Object.values(routesConfig).find(route => location.pathname.match(route.regexPath));
    if (currentRoute) {
      const { subPageName } = currentRoute;
      tealiumPageLoad(subPageName);
    }
  }, [location]);

  return null;
};

export default PageTracker;
