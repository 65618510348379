const routesConfig = {
  CLAIM_STATUS: { path: '/claim-status', regexPath: /\/claim-status[/]{0,1}$/, subPageName: 'ClaimStatus' },
  REGISTRATION: { path: '/registration', regexPath: /\/registration[/]{0,1}$/, subPageName: 'Registration' },
  CONFIRMATION: { path: '/confirmation', regexPath: /\/confirmation[/]{0,1}$/, subPageName: 'Confirmation' },
  ERROR: { path: '/error', regexPath: /\/error[/]{0,1}$/, subPageName: 'Error' },
  CLAIM_SEARCH: { path: '/claim-search', regexPath: /\/claim-search[/]{0,1}$/, subPageName: 'ClaimSearch' },
  ROOT: { path: '/', regexPath: /^\/$/, subPageName: 'Root' },
  // Add new routes here as required
};

export default routesConfig;